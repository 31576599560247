import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-gallery/styles/css/image-gallery.css"
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./index.css";
import 'handsontable/dist/handsontable.full.css';
// Elements
import { BrowserRouter, Route, Routes } from "react-router-dom";
// views
import Login from "./views/login";
import TaskManager from "./views/taskManager";
import CreateTask from "./views/createTask";
import UpdateProductTypes from "./views/updateProductTypes";
import ProductPreview from "./views/productPreview";
import WorkingTask from "./views/workingTask";
import MediaFiles from "./views/uploadMediaFiles";
import UploadProductImages from "./views/uploadProductImages";
import CreateProduct from "./views/createProduct";
import UserManager from "./views/userManager";
import Discounts from "./views/discounts"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="*" component={<div><h3>404 - Not found</h3></div>}/>
      <Route exact path="/" element={<Login/>}/>
      <Route exact path="/taskManager" element={<TaskManager/>}/>
      <Route exact path="/createTask" element={<CreateTask title="Crear Tarea con todos los UPCs" api="task/getUnassigned" originUrl={"createTask"}/>}/>
      <Route exact path="/createNewTask" element={<CreateTask title="Crear Tarea con UPCs nuevos" api="product/getNew?action=tasks" originUrl={"createNewTask"}/>}/>
      <Route exact path="/createIncompleteTask" element={<CreateTask title="Crear Tarea con UPCs incompletos" api="task/productProgress" originUrl={"createIncompleteTask"}/>}/>
      <Route exact path="/updateProductTypes" element={<UpdateProductTypes/>}/>
      <Route exact path="/productPreview" element={<ProductPreview/>}/>
      <Route exact path="/WorkingTask" onLeave={ () => {window.cellsColorsList = []} } element={<WorkingTask/>}/>
      <Route exact path="/mediaFiles" element={<MediaFiles/>}/>
      <Route exact path="/UploadProductImages" element={<UploadProductImages/>}/>
      <Route exact path="/CreateProduct" element={<CreateProduct title={"Crear productos"} edit={false} readOnly={false}/>}/>
      <Route exact path="/EditProducts" element={<CreateProduct title={"Editar productos"} edit={true} readOnly={false}/>}/>
      <Route exact path="/SearchProducts" element={<CreateProduct title={"Buscar productos"} edit={true} readOnly={true}/>}/>
      <Route exact path="/UserManager" element={<UserManager/>}/>
      <Route exact path="/Discounts" element={<Discounts title={"Descuentos"}/>}/>
    </Routes>
  </BrowserRouter>
);

reportWebVitals();