import React, { useEffect } from "react";
import viastaraLogo from "../img/viastaraLogo.svg";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { isLogin, logout } from "./commonFunctions";
import { ListTask, ClipboardCheck, ClipboardPlus, ClipboardMinus, FileEarmarkArrowUp, PersonXFill, BagPlus, BagCheck, Search, People, Tags } from 'react-bootstrap-icons';
import NavDropdown from 'react-bootstrap/NavDropdown';

const SideMenu=(props)=>{

    useEffect(()=>{
        isLogin();
        // eslint-disable-next-line
    },[]);
    const adminFeatures = () =>{
        if(localStorage.getItem("level") !== "3"){
            return(<></>);
        }
        else{
            return(
                <> 
                    <Nav.Link href="/UserManager" onClick={()=>doFunction()}>
                        <People color="white" size={30} /> {"\u00A0"} Usuarios
                    </Nav.Link>
                    <Nav.Link href="/createTask" onClick={()=>doFunction()}>
                        <ClipboardCheck color="white" size={30} /> {"\u00A0"} Crear tareas
                    </Nav.Link>
                    <Nav.Link href="/createNewTask" onClick={()=>doFunction()}>
                        <ClipboardPlus color="white" size={30} /> {"\u00A0"} Nuevos productos
                    </Nav.Link>
                    <Nav.Link href="/createIncompleteTask" onClick={()=>doFunction()}>
                        <ClipboardMinus color="white" size={30} /> {"\u00A0"} Stock incompleto
                    </Nav.Link>
                    <Nav.Link href="/updateProductTypes" onClick={()=>doFunction()}>
                        <FileEarmarkArrowUp color="white" size={30} /> {"\u00A0"} Actualizar Product Types
                    </Nav.Link>
                </>
            );
        }
    }
    const basicInformation = () =>{
        return(
            <>
                <Nav.Link href="/CreateProduct" onClick={()=>doFunction()}>
                    <BagPlus color="white" size={30} /> {"\u00A0"} Crear Productos
                </Nav.Link>
                <Nav.Link href="/EditProducts" onClick={()=>doFunction()}>
                    <BagCheck color="white" size={30} /> {"\u00A0"} Editar productos
                </Nav.Link>
                <Nav.Link href="/SearchProducts" onClick={()=>doFunction()}>
                    <Search color="white" size={30} /> {"\u00A0"} Buscar productos
                </Nav.Link>
                {/* <Nav.Link href="/Discounts" onClick={()=>doFunction()}>
                    <Tags color="white" size={30} /> {"\u00A0"} Descuentos
                </Nav.Link> */}
                <NavDropdown.Divider className="dividerMenu"/>
            </>
        );
    }
    const doFunction = async() =>{
        if(props.function){
            await props.function(true);
        }
    }
    return(
        <>
            {/* {BackButtonListener()} */}
            {[false].map((expand) => (
            <Navbar key={expand} expand={expand} className="mb-5 navbar-dark bg-dark" fixed="top">
                <Container fluid>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} ><img style={{width:"100px"}} src={viastaraLogo} alt=""/></Navbar.Toggle>
                    <Navbar.Brand>{props.title}</Navbar.Brand>
                    <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="start" className="text-bg-dark">
                        <Offcanvas.Header closeButton closeVariant="white">
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}><img style={{width:"100px"}} src={viastaraLogo} alt=""/></Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3 ">
                                {basicInformation()}                                
                                <Nav.Link href="/taskManager" onClick={()=>doFunction()}>
                                    <ListTask color="white" size={30} /> {"\u00A0"} Administrador de tareas
                                </Nav.Link>
                                {adminFeatures()}
                                <Nav.Link className="sidebar-footer" href="/" onClick={()=>logout()}>
                                    <PersonXFill color="white" size={30} /> {"\u00A0"} Cerrar sesión
                                </Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            ))}
        </>
    );
}

export default SideMenu