import React, { useState, useEffect } from "react";
import SideMenu from "../Components/sideMenu";
import Swal from "sweetalert2"
import { HotTable } from "@handsontable/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Button from "react-bootstrap/Button";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { Typeahead } from 'react-bootstrap-typeahead';
import { PlusSquare, DashSquare, Eye } from 'react-bootstrap-icons';
import { substituteHeaders, getAxios, postAxios, deleteAxios, isEmpty, clearSaveData } from "../Components/commonFunctions";
import Select from 'react-select';

const statusList = [];

const CreateTask=(props)=>{
    const [getTab, setTab] = useState("1");
    const [getUsersList, setUsersList] = useState([]);
    const [getBrandList, setBrandList] = useState([]);
    const [getBrandSelected, setBrandSelected] = useState([]);
    const [getHeaders, setHeaders] = useState([]);
    const [getColumns, setColumns] = useState([]);
    const [getText, setText] = useState("");

    const [getTaskName, setTaskName] = useState("");
    const [getUser, setUser] = useState("");

    const [getDefaultData, setDefaultData] = useState([]);
    const [getDataNoAsignados, setDataNoAsignados] = useState([]);
    const [getDataAsignados, setDataAsignados] = useState([]);
    
    const handsonTable = React.createRef();
    const handsonTableSettings = {
        licenseKey:"non-commercial-and-evaluation",
        data: getDefaultData,
        colHeaders:getHeaders,
        columns:getColumns,
        colWidths:[50,70,150,30,50,50,50],
        stretchH: "all",
        rowHeaders:false,
        outsideClickDeselects: false,
        columnSorting: true,
        manualColumnResize: true,
        wordWrap: false,
        height:"55vh"
    };

    useEffect(()=>{
        if(localStorage.getItem("level") !== "3"){
            window.location.href = "/taskManager";
        }
        getUsers();
        getbrands();
        getUpcs();
        // eslint-disable-next-line
    },[]);
    useEffect(()=>{
        changeData();
        // eslint-disable-next-line
    },[getTab]);
    useEffect(()=>{
        filters();
        // eslint-disable-next-line
    },[getDefaultData, getText, getBrandSelected, handsonTable]);

    const loadInformation = (data) => {
        let information = localStorage.getItem(props.originUrl);
        if (!isEmpty(information)){
            let newNoAsignados = data;
            information = JSON.parse(information);
            setDataAsignados(information.asignados);
            for (let index = 0; index < information.asignados.length; index++) {
                let indexToDelete = newNoAsignados.findIndex(x => x.upc === information.asignados[index].upc);
                newNoAsignados.splice(indexToDelete,1);
            }
            setDataNoAsignados(newNoAsignados);
        }
        clearSaveData();
    }
    const getUsers = async() =>{
        await getAxios("task/getUsers").then(response => {
            if(response.success === false){
                console.log("ERROR: users could not be loaded");
            }
            else{
                let list = [];
                for (let index = 0; index < response.length; index++) {
                    // list.push(<option key={response[index].uid} value={response[index].user}> {response[index].name + " " + response[index].lastname} </option>);
                    list.push({value:response[index].user, label:response[index].name + " " + response[index].lastname});
                }
                setUsersList(list);
            }
        });
    }
    const getbrands = async() =>{
        await getAxios("getBrands").then(response=>{
            if(response.success === false){
                console.log("ERROR: brands could not be loaded");
            }
            else{
                let list = [];
                for (let index = 0; index < response.length; index++) {
                    list.push(response[index].name);
                }
                setBrandList(list);
            }
        });
    }
    const getUpcs = async() =>{
        await getAxios(props.api).then(response=>{
            let data = response.products !== undefined ? response.products : response;
            setDataNoAsignados(data);
            setDefaultData(data);
            setHeaders(createHeaders(data[0]));
            setColumns(createColumns(data[0]));
            loadInformation(data);
        });
    }
    const createHeaders = (row) =>{
        let headersFromData = Object.keys(row);
        let newHeaders = [];
        for (let index = 0; index < headersFromData.length; index++) {
            newHeaders.push(substituteHeaders(headersFromData[index]));
        }
        return newHeaders;
    }
    const createColumns = (row) =>{
        let columnsFromData = Object.keys(row);
        let newColumns = [];
        for (let index = 0; index < columnsFromData.length; index++) {
            newColumns.push({data:columnsFromData[index], readOnly: true});
        }
        return newColumns;
    }
    const createObject = (item) =>{
        let newObject = {};
        for (let index = 0; index < getColumns.length; index++) {
            newObject[getColumns[index].data] = item[index];
        }
        return newObject;
    }
    const changeData = () =>{
        let instance = handsonTable.current.hotInstance;
        instance.deselectCell();
        if(getTab === "1"){
            setDefaultData(getDataNoAsignados);
        }
        else{
            setDefaultData(getDataAsignados);
        }
        instance.updateSettings({data:getDefaultData});
    }
    const moveUpcs = () =>{
        let instance = handsonTable.current.hotInstance;
        let selected = instance.getSelected();
        let newNoAsignados = getDataNoAsignados;
        let newAsignados = getDataAsignados;
        for (let index = 0; index < selected.length; index++) {
            let item = selected[index];
            let startRow = Math.min(item[0], item[2]) < 0 ? 0 : Math.min(item[0], item[2]);
            let endRow = Math.max(item[0], item[2]) < 0 ? 0: Math.max(item[0], item[2]);
            for (let rowIndex = endRow; rowIndex >= startRow; rowIndex -=1)	{
                let row = createObject(instance.getDataAtRow(rowIndex));
                if(getTab === "1"){
                    let indexToDelete = newNoAsignados.findIndex(x => x.upc === row.upc);
                    newNoAsignados.splice(indexToDelete,1);
                    newAsignados.unshift (row);
                }
                else{
                    let indexToDelete = newAsignados.findIndex(x => x.upc === row.upc);
                    newAsignados.splice(indexToDelete,1);
                    newNoAsignados.unshift (row);                    
                }
            }
        }
        setDataNoAsignados(newNoAsignados);
        setDataAsignados(newAsignados);
        changeData();
    }
    const deleteTask = async(tid) => {
        let json = {tid:tid}
        await deleteAxios("task/delete?tid=" + tid, JSON.stringify(json)).then(response => {});
    }
    const createNewtask = () =>{
        if(getTaskName === "" || getUser === "" || getDataAsignados.length === 0){
            Swal.fire({ icon: "warning", title: "Espera!", text: "Falta informacion", showConfirmButton: false, timer: 1500 });
        }
        else{
            let json = JSON.stringify({title:getTaskName, assignee:getUser, comment:"New Task"});
            postAxios("task/create", json).then(response=>{
                if(response.success === false){
                    Swal.fire({ icon: "error", title: "Error", text: "No se pudo crear la tarea", showConfirmButton: false, timer: 1500 });
                }
                else{
                    let upcs = [];
                    for (let index = 0; index < getDataAsignados.length; index++) {
                        upcs.push(getDataAsignados[index].upc);
                    }
                    let obj = {tid: response.tid, upcs:upcs}
                    postAxios("task/addProducts", JSON.stringify(obj)).then(response =>{
                        if(response.success === true){
                            Swal.fire({icon: "success", title: "Tarea " + getTaskName +" creada", button: true}).then(()=>{
                                window.location.href = "/taskManager";
                            });
                        }
                        else{
                            deleteTask(response.tid);
                            Swal.fire({icon: "warning", title: "Error no se pudo agregar los productos a la tarea ", button: true });
                        }
                    }).catch((error) => {
                        deleteTask(response.tid);
                        Swal.fire({icon: "warning", title: "Error no se pudo agregar los productos a la tarea ", button: true });
                    });
                }
            }).catch((error) => {
                Swal.fire({ icon: "error", title: "Error", text: "No se pudo crear la tarea", showConfirmButton: false, timer: 1500 });
            });
        }
    }
    const showPreview = async() => {
        let instance = handsonTable.current.hotInstance;
        let selected = instance.getSelected();
        if(!isEmpty(selected)){
            let row = Math.min(selected[0][0], selected[0][2]) < 0 ? 0 : Math.min(selected[0][0], selected[0][2]);
            let dataRow = instance.getDataAtRow(row);
            await getAxios("task/getDetailByUPC?upc=" + dataRow[1]).then(response => {
                if(isEmpty(response[0])){
                    Swal.fire({ icon: "error", title: "Error", text: "No se pudo obtener informacion", showConfirmButton: false, timer: 1500 });
                }
                else{
                    localStorage.setItem( "tid", "");
                    localStorage.setItem( "selectedItem", JSON.stringify(response[0]));
                    localStorage.setItem( "originPage", props.originUrl);
                    localStorage.setItem( "originName", "Asignacion de tareas");
                    let information = {asignados: getDataAsignados};
                    localStorage.setItem( props.originUrl, JSON.stringify(information));
                    window.location.href = "/productPreview";
                }
            });
        }
        else{
            Swal.fire({ icon: "info", title: "Espera!", text: "Selecciona un producto antes.", showConfirmButton: false, timer: 1500 });
        }
    }

    // filtros
    const filterCheckBox=(status)=>{
        if(!statusList.includes(status)){
            statusList.push(status);
        }
        else{
            let index = statusList.indexOf(status);
            if(index > -1){
                statusList.splice(index,1);
            }
        }
        changeData();
        filters();
    }
    const filters =()=>{
        let instance = handsonTable.current.hotInstance;
        instance.deselectCell();

        let data = instance.getData();
        let newData = [];

        data.forEach(element => {
            newData.push(createObject(element));
        });
        // por marca
        if(getBrandSelected.length > 0){
            for (let index = newData.length - 1; index > -1; index--) {
                if(!getBrandSelected.includes(newData[index].brand)){
                    newData.splice(index,1);
                }
            }
        }
        // por status
        if(statusList.length > 0){
            for (let index = newData.length - 1; index > -1; index--) {
                if(!statusList.includes(newData[index].status)){
                    newData.splice(index,1);
                }
            }
        }
        // por texto
        if(getText !== ""){
            for (let index = newData.length - 1; index > -1; index--) {
                let row = Object.values(newData[index]).join("*,").toLowerCase();
                if(!row.includes(getText.toLowerCase())){
                    newData.splice(index,1);
                }
            }
        }
        instance.updateSettings({data:newData});
    }
    return(
        <>
        <SideMenu title={props.title}/>
        <Container className="marginTopContainer">
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active href="/taskManager">Administrador de tareas</Breadcrumb.Item>
                        <Breadcrumb.Item active href="#">Asignación de tareas</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={4} lg={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nombre de la tarea:</Form.Label>
                        <Form.Control onChange={(e) => setTaskName(e.target.value)} type="text" placeholder="Ejemplo: Lineal no. 1" />
                    </Form.Group>
                </Col>
                <Col sm={12} md={4} lg={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Usuarios:</Form.Label>
                        <Select placeholder="Seleciona un usuario" className="overTable" onChange={(e) => setUser(e.value)} options={getUsersList}></Select>
                    </Form.Group>
                </Col>
                <Col sm={12} md={4} lg={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{"\u00A0"}</Form.Label>
                        <Button variant="danger" onClick={()=>createNewtask()} className="fullWidth redButtonColor">Asignar tarea</Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <hr/>
                <Col sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Buscar UPCs:</Form.Label>
                        <Form.Control onChange={(e)=>setText(e.target.value)} value={getText} type="text" placeholder="Ejemplo: 999999999" />
                    </Form.Group>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Marcas:</Form.Label>
                        <Typeahead id="selectBrand" onChange={(e)=>setBrandSelected(e)} options={getBrandList} selected={getBrandSelected} multiple placeholder="Selecciona una marca" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Check inline onChange={()=>filterCheckBox("NEW")} label="Nuevo" type="checkbox" id={"cbx_new"} />
                        <Form.Check inline onChange={()=>filterCheckBox("ACTIVE")} label="Activo" type="checkbox" id={"cbx_active"} />
                        <Form.Check inline onChange={()=>filterCheckBox("INACTIVE")} label="Inactivo" type="checkbox" id={"cbx_inactive"} />
                        <Form.Check inline onChange={()=>filterCheckBox("DISCONTINUE")} label="Descontinuado" type="checkbox" id={"cbx_discontinued"} />
                    </Form.Group>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <ButtonToolbar className="justify-content-between">
                        <ButtonGroup>
                            <ToggleButton checked={getTab === "1"} onChange={(e) => setTab(e.currentTarget.value)} value="1" name="radio" key="radio-1" id="radio-1" type="radio" variant="outline-dark">
                                No asignados
                            </ToggleButton>
                            <ToggleButton checked={getTab === "2"} onChange={(e) => setTab(e.currentTarget.value)} value="2" name="radio" key="radio-2" id="radio-2" type="radio" variant="outline-dark">
                                Asignados
                            </ToggleButton>
                        </ButtonGroup>
                        <div>
                            <Button onClick={()=>showPreview()} variant="dark"><Eye color="white" size={20}/>{"\u00A0"} Preview</Button>{"\u00A0"}
                            <Button onClick={()=>moveUpcs()} variant="dark">{getTab === "1" ? <PlusSquare color="white" size={20}/> : <DashSquare color="white" size={20}/>} {"\u00A0"} {getTab === "1" ? "Agregar" : "Remover"}</Button>
                        </div>
                    </ButtonToolbar>
                </Col>
            </Row>
            <Row>
                <Col>
                    <HotTable id="mainTable" ref={handsonTable} settings={handsonTableSettings}/>
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default CreateTask;