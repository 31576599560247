import React, { useState, useEffect } from "react";
import SideMenu from "../Components/sideMenu";
import { HotTable } from "@handsontable/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2"
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { SendFill, TrashFill } from 'react-bootstrap-icons'; //FileEarmarkArrowUpFill
import { getAxios, postAxios, putFetchToken, msrp_off } from "../Components/commonFunctions";
import { countValidator14, countValidator20, countValidator64, countValidator100, countValidator128, listValidator } from "../Components/validators"
import InputGroup from 'react-bootstrap/InputGroup';
import { TextRenderer, NumericRenderer, AutocompleteRenderer, CheckboxRenderer, LimitValueRenderer } from "../Components/renderers";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/Dropdown";
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
 
const CreateProduct=(props)=>{
    let invalidRows = [];

    const [getSearchField, setSearchField] = useState("");
    const [getColumns, setColumns] = useState([]);
    const [getErpOptions, setErpOptions] = useState({});
    const [getisDisable, setisDisable] = useState(true);

    // const bufferTable = React.createRef();
    // const buferTableSettings = {
    //     licenseKey:"non-commercial-and-evaluation",
    // }

    const ProductsHandsonTable = React.createRef();    
    const ProductsHandsonTableSettings = {
        licenseKey:"non-commercial-and-evaluation",
        colHeaders:[
            "ecdid",
                "Unidad de medida",
                "Site",
            "Brand",
            "Item Number",
            "UPC",
            "EAN",
            "Item Description",
            "System Color",
            "Department",
            "Class",
            "Subclass",
            "Replenishment",
            "Purchase Status",
            "Company",
                "Customer Type",
                "Tax",
            "Vendor",
                "Serie",
            "Cost",
            "Landed Cost",
            "Currency",
            "MSRP", //(USD)
            "PVP", //(MXN)
                "Tipo de Cambio Alta",
                "Margen Máximo VMX %",
                "Vendor Retail VMX Máximo %",
            "FOB", //(USD)
            // "Fob Vendor", //(USD)
                "% OFF MSRP",
                "Master Pack Quantity",
                "Inner Pack Quantity",
                "MOQ",
            "Product Length",
            "Product Width",
            "Product Height",
            "Dimension Unit Product",
            "Product Weight",
            "Weight Unit Product",
            "Package Length",
            "Package Width",
            "Package Height",
            "Dimension Unit Package",
            "Package Weight",
            "Weight Unit Package",
                "Tipo Empaque SAT",
                "Material Peligroso",
                "Codigo Material Peligroso",
            "Units per Pallet",
            "Sistema métrico",
            
            "Schedule B",
            "País de Origen",
            "Material de composición",
            // "Canal"
        ],
        columns: getColumns,
        colWidths:[
            100,100,100,150,150,150,150,150,150,150,
            150,150,150,150,150,150,150,(150*3),150,150,
            150,150,150,150,160,200,150,150,150,150, 
            150,150,150,150,150,150,150,150,150,150, 
            150,150,150,150,150,200,150,150,150,150,
            150,200,200
        ], 
        stretchH: "all",
        rowHeaders:true,
        outsideClickDeselects: false,
        // columnSorting: true,
        manualColumnResize: true,
        wordWrap: false,
        contextMenu: !props.edit ? {
            items: {
                row_below: {
                    name: 'Crear renglón',
                    callback(key, selection, clickEvent) {
                        setTimeout(() => {
                            createNewProduct();
                        }, 0);
                    }
                },
                Create_variant: { 
                    name:"Crear variante",
                    callback(key, selection, clickEvent) {
                        setTimeout(() => {
                            createNewVariant(0);
                        }, 0);
                    }
                },
                Create_variant_company: { 
                    name:"Crear variante Compania",
                    callback(key, selection, clickEvent) {
                        setTimeout(() => {
                            createNewVariant(1);
                        }, 0);
                    }
                },
                remove_product: {
                    name: 'Eliminar renglón',
                    callback(key, selection, clickEvent) {
                        setTimeout(() => {
                            removeProduct();
                        }, 0);
                    }
                }
            }
        }: 
        {items: {
                remove_product: {
                    name: 'Eliminar renglón',
                    callback(key, selection, clickEvent) {
                        setTimeout(() => {
                            removeProduct();
                        }, 0);
                    }
                }                
        }} ,
        height:"60vh",
        search: true,
        multiColumnSorting: true,
        hiddenColumns: {
            columns:[0,1,2]
        },
        afterSetDataAtCell: function(changes, source) {
            if (changes === null) return;
            changes.forEach((_, index) => {
                const row = changes[index][0];
                const column = changes[index][1];
                const oldValue = changes[index][2];
                const value = changes[index][3];
                if(value === null || oldValue === value){
                    return;
                }
                // let newSource = [];
                switch (column) {
                    case "Department":
                        let newSource = getNewSourceList(value, "departments", "mainclasses", "edid");
                        this.setCellMeta(row, this.propToCol("Class"), 'source', newSource);
                        this.setDataAtRowProp(row, "Class", null);
                        this.setDataAtRowProp(row, "Subclass", null);
                        break;
                    case "Class":
                        let newSource2 = getNewSourceList(value, "mainclasses", "subclasses", "emcid");
                        this.setCellMeta( row, this.propToCol("Subclass"), 'source', newSource2 );
                        this.setDataAtRowProp(row, "Subclass", null);
                        break;
                    default:
                        break;
                }
            });
        },
        beforeChange: function(changes, source){
            if (changes === null) return;
                changes.forEach((_, index) => {
                    const row = changes[index][0];
                    const column = changes[index][1];
                    const oldValue = changes[index][2];
                    const value = changes[index][3];
                    if(value === null || oldValue === value){
                        return;
                    }
                    if(typeof value == 'string' && value.includes("…")){
                        this.setDataAtRowProp(row, column, value.replace("…","..."));
                    }
                });
        },
        afterChange: function(changes, source){
            if (changes === null) return;
                changes.forEach((_, index) => {
                    const row = changes[index][0];
                    const column = changes[index][1];
                    const oldValue = changes[index][2];
                    const value = changes[index][3];
                    if(value === null || oldValue === value){
                        return;
                    }
                    switch (column) {
                        case "Cost":
                        case "MSRP":
                            const cost = this.getDataAtRowProp(row, "Cost");
                            const msrp = this.getDataAtRowProp(row, "MSRP");
                            this.setDataAtRowProp(row, "% OFF MSRP", msrp_off(cost, msrp));
                            break;
                        case "Master Pack Quantity":
                        case "Inner Pack Quantity":
                        case "MOQ":
                        case "Units per Pallet":
                            if(typeof value !== 'number' || value === null){
                                this.setDataAtRowProp(row, column, "");
                            }
                            else{
                                this.setDataAtRowProp(row, column, parseInt(value));
                            }
                            break;
                        case "Department":
                            let sourceDepartment = getErpOptions["departments"].map(item => item.name);
                            if(!sourceDepartment.includes(value)){
                                this.setDataAtRowProp(row, column, "");
                            }
                            break;
                        case "Class":
                            let sourceClass = getNewSourceList(this.getDataAtRowProp(row, "Department"), "departments", "mainclasses", "edid");
                            if(!sourceClass.includes(value)){
                                this.setDataAtRowProp(row, column, "");
                            }
                            break;
                        case "Subclass":
                            let sourceSubClass = getNewSourceList(this.getDataAtRowProp(row, "Class"), "mainclasses", "subclasses", "emcid");
                            if(!sourceSubClass.includes(value)){
                                this.setDataAtRowProp(row, column, "");
                            }
                            break;
                        case "Serie":
                        case "Material Peligroso":
                            let true_options = ["TRUE", "VERDADERO", "true", "verdadero", "True", "Verdadero", "1", true, 1];
                            if(true_options.includes(value)){
                                this.setDataAtRowProp(row, column, true);
                            }
                            else{
                                this.setDataAtRowProp(row, column, false);
                            }
                            break;
                        default:
                            break;
                    }
                    
                }
            );
        },
        afterValidate(isValid, value, row, prop, source){
            if(!isValid && !invalidRows.includes(row)){
                invalidRows.push(row);
            }
            if(isValid && invalidRows.includes(row)){
                const index = invalidRows.findIndex((item) => item === row );
                invalidRows.splice(index, 1);
            }
        },
        afterSelection: (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
            preventScrolling.value = false;
            isButtonsDisable(row);
            if([8, 9 ,10].includes(column)){
                const instance = ProductsHandsonTable.current.hotInstance;
                const departmentValue = instance.getDataAtRowProp(row, "Department");
                if(departmentValue){
                    let ClassSource = getNewSourceList(departmentValue, "departments", "mainclasses", "edid");
                    instance.setCellMeta( row, instance.propToCol("Class"), 'source', ClassSource );
                }
                const ClassValue = instance.getDataAtRowProp(row, "Class");
                if(ClassValue){
                    let ClassSource = getNewSourceList(ClassValue, "mainclasses", "subclasses", "emcid");
                    instance.setCellMeta( row, instance.propToCol("Subclass"), 'source', ClassSource );
                } 
            }
        }
    };

    useEffect(()=>{
        // bufferTable.current.hotInstance.selectCell(0, 0);
        getData();
        const instance = ProductsHandsonTable.current.hotInstance;
        instance.updateSettings({data:!props.edit ? undefined : []});
        // eslint-disable-next-line
    },[]);
    useEffect(()=>{
        if(getErpOptions !== undefined && Object.keys(getErpOptions).length > 0){
            createColumns();
        }
        // eslint-disable-next-line
    },[getErpOptions]);

    const isButtonsDisable = (row) =>{
        let instance = ProductsHandsonTable.current.hotInstance;
        let result = instance.isEmptyRow(row);
        setisDisable(result);
    }
    const getData = async() =>{
        await getAxios("getErpOptions").then(response => {
            setErpOptions(response);
        });
    }
    const getNewSourceList = (departmentName, from, to, id) =>{
        let object = getErpOptions[from].find((element) => element.name === departmentName);
        if(object === undefined){return null}
        let newList = getErpOptions[to].filter(item => (item[id] === object[id]));
        return newList.map(item => item.name);
    }
    const getDataFromItem = (list, field, value) =>{
        if(value === null){
            return { name: null, code: null, ecid: null, ebid: null, evid: null, edid: null, emcid: null, escid: null, ecoid: null }
        }
        let item = getErpOptions[list].find((element) => element[field].toString().toLowerCase() === value.toString().toLowerCase());
        if(item === undefined){
            item = { name: null, code: null, ecid: null, ebid: null, evid: null, edid: null, emcid: null, escid: null, ecoid: null }
        }
        return item;
    }
    const createColumns = () =>{
        let columns = [];
        // "UPC", "Item Number",  12 - 20
        const validator_64 = ["Purchase Status", "Sales Status", "Replenishment"];
        const numeric = [
            "Master Pack Quantity",
            "Inner Pack Quantity",
            "MOQ",
            "MSRP",
            "PVP",
            "FOB",
            "Product Length",
            "Product Width",
            "Product Height",
            "Product Weight",
            "Package Length",
            "Package Width",
            "Package Height",
            "Package Weight",
            "Cost",
            "Landed Cost",
            "Tax",
            "Tipo de Cambio Alta",
            "Margen Máximo VMX %",
            "Vendor Retail VMX Máximo %",
            "Units per Pallet",
            "% OFF MSRP"
        ];
        const integerNum = [
            "Master Pack Quantity",
            "Inner Pack Quantity",
            "MOQ",
            "Units per Pallet",                   
        ];
        const dropdownColumns = [
            {name: "Brand", source: getErpOptions["brands"].map(item => item.name)},
            {name: "Company", source: getErpOptions["companies"].map(item => item.name)},
            {name: "Vendor", source: getErpOptions["vendors"].map(item => item.lid)},
            {name: "Department", source: getErpOptions["departments"].map(item => item.name)},
            {name: "Class", source: []},
            {name: "Subclass", source: []},
            {name: "Dimension Unit Product", source: ["mm", "cm", "m", "in", "ft"]},
            {name: "Weight Unit Product", source: ["gr", "kg", "oz", "lb"]},
            {name: "Dimension Unit Package", source: ["mm", "cm", "m", "in", "ft"]},
            {name: "Weight Unit Package", source: ["gr", "kg", "oz", "lb"]},
            {name: "PVP Currency", source: ["USD", "MXN"]},
            {name: "Currency", source: ["USD", "MXN"]},
            {name: "System Color", source: getErpOptions["colors"].map(item => item.name)},
            {name: "Replenishment", source: ["Lineal Base","JIT","ROP"]},
            {name: "Customer Type", source: getErpOptions["customer_types"].map(item => item.type)},
            {name: "Tipo Empaque SAT", source: getErpOptions["sat_pkg_types"].map(item => item.description)},
            {name: "Codigo Material Peligroso", source: getErpOptions["sat_hazard_codes"].map(item => item.description)},
            {name: "Purchase Status", source: ["New","Active","Inactive","Discontinued"]},
            {name: "Unidad de medida", source: ["EACH"]},
            {name: "País de Origen", source: getErpOptions["countries"].map(item => item.name)},
            {name: "Sistema métrico", source: ["kg", "g"]},
        ]
        const checkbox = [ "Serie", "Material Peligroso" ];

        for (let index = 0; index < ProductsHandsonTableSettings.colHeaders.length; index++) {
            let name = ProductsHandsonTableSettings.colHeaders[index];
            let column = {data: name, readOnly: props.readOnly, renderer: TextRenderer};
            let dropdownItem = dropdownColumns.find(item => item.name === name);
            if(dropdownItem){
                column.type = "autocomplete";
                column["source"] = dropdownItem.source;
                column.strict = true;
                column.allowInvalid = false;
                column.renderer = AutocompleteRenderer;
                if(["Department","Class","Subclass"].includes(dropdownItem.name)){
                    column.validator = listValidator;
                }
            }            
            if(checkbox.includes(name)){
                column.type = "checkbox";
                column.allowInvalid = false;
                column.checkedTemplate = true;
                column.uncheckedTemplate = false;
                column.renderer = CheckboxRenderer;
            }
            if(validator_64.includes(name)){
                column.validator = countValidator64;
            }
            if(numeric.includes(name)){
                column.type = "numeric";
                column.numericFormat = {pattern: '0,0.00'}
                column.renderer = NumericRenderer;
            }
            if(integerNum.includes(name)){
                column.type = "numeric";
                column.numericFormat = {pattern: '0'}
                column.renderer = NumericRenderer;
            }
            if("UPC" === name) {
                column.validator = countValidator14;
            }
            if("Item Number" === name){
                column.validator = countValidator20;
            }
            if("Item Description" === name){
                column.validator = countValidator100;
            }
            if("Brand Color" === name){
                column.validator = countValidator128;
            }
            if(name === "Margen Máximo VMX %" || name === "Vendor Retail VMX Máximo %"){
                column.renderer = LimitValueRenderer;
            }
            columns.push(column);
        }
        setColumns(columns);
    }
    const createNewProduct = () =>{
        let instance = ProductsHandsonTable.current.hotInstance;
        instance.alter('insert_row_below');
        // const selection = instance.getSelectedLast();
        // if (selection === undefined){
        //     Swal.fire({icon: "info", title: "Espera!", text: "Selecciona una fila primero", showConfirmButton: false, timer: 1500});
        //     return;
        // }
        // instance.alter('insert_row_below', selection[0], 1); // bewlow selection        
    }
    const removeProduct = () =>{
        let instance = ProductsHandsonTable.current.hotInstance;
        const selection = instance.getSelectedLast();
        if (selection === undefined){
            Swal.fire({icon: "info", title: "Espera!", text: "Selecciona una fila primero", showConfirmButton: false, timer: 1500});
            return;
        }
        else{
            Swal.fire({
                icon: "warning",
                title: "Espera!",
                text: "¿Estas seguro de eliminar este producto?",
                showCancelButton: true,
                confirmButtonText: 'Borrar',
                cancelButtonText: "Cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    instance.alter('remove_row', selection[0], 1);
                }
            });
        }        
    }
    const clearTable = () =>{
        Swal.fire({
                icon: "warning",
                title: "Espera!",
                text: "¿Estas seguro de limpiar completamente la tabla?",
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: "Cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
    }
    const createNewVariant = (typeIndex) =>{
        // 0 variant, 1 Company
        const variantType = [
            [ "Brand Color","System Color","Item Number","UPC","Item Description"],
            [ "Company", "Vendor", "Purchase Status", "Sales Status", "Replenishment", "Cost",  "Cost unit", "Landed Cost", "Landed Cost Unit", "% OFF MSRP", "Customer Type", "Currency", "Margen Máximo VMX %", "Vendor Retail VMX Máximo %" ]
        ];

        let instance = ProductsHandsonTable.current.hotInstance;
        const selection = instance.getSelectedLast();
        if (selection === undefined){
            Swal.fire({icon: "info", title: "Espera!", text: "Selecciona una fila primero", showConfirmButton: false, timer: 1500});
            return;
        }

        let row = instance.getSelected()[0][0];
        let data = instance.getDataAtRow(row);

        instance.alter('insert_row_below', selection[0], 1);

        for (let index = 0; index < data.length; index++) {
            if(variantType[typeIndex].includes(instance.getColHeader(index))){
                instance.setDataAtCell(row + 1, index, "");
            }
            else{
                instance.setDataAtCell(row + 1, index, data[index]);
            }
        }
    }
    const removeDuplicate = (newUpcs) =>{
        const instance = ProductsHandsonTable.current.hotInstance;
        let InTable = [""];
        let clearData = [];
        for (let index = 0; index < instance.countRows(); index++) {            
            InTable.push(instance.getDataAtRowProp(index, "UPC"));
            InTable.push(instance.getDataAtRowProp(index, "Item Number"));
        }
        for (let index = 0; index < newUpcs.length; index++) {
            if(!InTable.includes(newUpcs[index])){                
                clearData.push(newUpcs[index]);
            }
        }
        return clearData;
    }
    const searchProducts = async(e) =>{
        const instance = ProductsHandsonTable.current.hotInstance;
        const upcs = getSearchField.replaceAll(" ","").replaceAll("\"","").replaceAll("'","").split(",");

        let json = {upcs:removeDuplicate(upcs)};
        await postAxios("searchErpProducts", JSON.stringify(json)).then(response => {
            for (let indexFound = 0; indexFound < response.found.length; indexFound++) {
                if(response.found[indexFound]["companies"].length < 1){
                    createNewProduct();

                    let dept = getDataFromItem("departments", "name", response.found[indexFound]["department"]).name;
                    let mainClass = dept !== null ? getDataFromItem("mainclasses", "name", response.found[indexFound]["mainclass"]).name : null;
                    let subClass =  mainClass !== null ? getDataFromItem("subclasses", "name", response.found[indexFound]["subclass"]).name : null;

                    let satPacking = getDataFromItem("sat_pkg_types", "spid", parseInt(response.found[indexFound]["sat_packing_type"])).description;
                    let hazard_code = getDataFromItem("sat_hazard_codes", "shcid", response.found[indexFound]["hazard_material_code"]).description;

                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Brand"), response.found[indexFound]["brand"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Item Description"), response.found[indexFound]["item_description"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Department"), dept);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Class"), mainClass);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Subclass"), subClass);

                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("FOB"), response.found[indexFound]["fob"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Product Length"), response.found[indexFound]["product_length"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Product Width"), response.found[indexFound]["product_width"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Product Height"), response.found[indexFound]["product_height"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Dimension Unit Product"), response.found[indexFound]["product_dimensions_unit"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Product Weight"), response.found[indexFound]["product_weight"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Weight Unit Product"), response.found[indexFound]["product_weight_unit"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Package Length"), response.found[indexFound]["pkg_length"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Package Width"), response.found[indexFound]["pkg_width"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Package Height"), response.found[indexFound]["pkg_height"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Dimension Unit Package"), response.found[indexFound]["pkg_dimensions_unit"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Package Weight"), response.found[indexFound]["pkg_weight"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Weight Unit Package"), response.found[indexFound]["pkg_weight_unit"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("System Color"), response.found[indexFound]["color_system"].toUpperCase());
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Item Number"), response.found[indexFound]["itemNumber"]);

                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("UPC"), response.found[indexFound]["upc"]);
                    // instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Fob Vendor"), response.found[indexFound]["fob_vendor"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Customer Type"), response.found[indexFound]["customer_type"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Tax"), response.found[indexFound]["tax"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Serie"), response.found[indexFound]["is_serial"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Tipo de Cambio Alta"), response.found[indexFound]["tc_alta_mx"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Margen Máximo VMX %"), response.found[indexFound]["max_margin_vmx"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Vendor Retail VMX Máximo %"), response.found[indexFound]["max_vendor_margin"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("% OFF MSRP"), response.found[indexFound]["msrp_off"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Master Pack Quantity"), response.found[indexFound]["master_pack_qty"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Inner Pack Quantity"), response.found[indexFound]["inner_pack_qty"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("MOQ"), response.found[indexFound]["moq"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Tipo Empaque SAT"), satPacking);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Material Peligroso"), response.found[indexFound]["hazard_material"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Codigo Material Peligroso"), hazard_code);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Unidad de medida"), response.found[indexFound]["unit_of_measurement"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Site"), response.found[indexFound]["site"]);

                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Units per Pallet"), response.found[indexFound]["units_per_pallet"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("EAN"), response.found[indexFound]["ean"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Sistema métrico"), response.found[indexFound]["metric_system"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Schedule B"), response.found[indexFound]["schedule_b"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("country_of_origin"), response.found[indexFound]["country_of_origin"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("material_composition"), response.found[indexFound]["schedule_b"]);
                    // instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Canal"), response.found[indexFound]["channel "]);
                }
                for (let indexCompany = 0; indexCompany < response.found[indexFound]["companies"].length; indexCompany++) {
                    createNewProduct();

                    let dept = getDataFromItem("departments", "name", response.found[indexFound]["department"]).name;
                    let mainClass = dept !== null ? getDataFromItem("mainclasses", "name", response.found[indexFound]["mainclass"]).name : null;
                    let subClass =  mainClass !== null ? getDataFromItem("subclasses", "name", response.found[indexFound]["subclass"]).name : null;
                    let vendor = getDataFromItem("vendors", "evid", response.found[indexFound]["companies"][indexCompany]["evid"]).lid;

                    let company = getDataFromItem("companies", "ecid", response.found[indexFound]["companies"][indexCompany]["ecid"]).name;
                    let satPacking = getDataFromItem("sat_pkg_types", "spid", parseInt(response.found[indexFound]["sat_packing_type"])).description;
                    let hazard_code = getDataFromItem("sat_hazard_codes", "shcid", response.found[indexFound]["hazard_material_code"]).description;

                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Brand"), response.found[indexFound]["brand"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Item Description"), response.found[indexFound]["item_description"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Department"), dept);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Class"), mainClass);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Subclass"), subClass);

                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("FOB"), response.found[indexFound]["fob"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Product Length"), response.found[indexFound]["product_length"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Product Width"), response.found[indexFound]["product_width"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Product Height"), response.found[indexFound]["product_height"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Dimension Unit Product"), response.found[indexFound]["product_dimensions_unit"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Product Weight"), response.found[indexFound]["product_weight"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Weight Unit Product"), response.found[indexFound]["product_weight_unit"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Package Length"), response.found[indexFound]["pkg_length"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Package Width"), response.found[indexFound]["pkg_width"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Package Height"), response.found[indexFound]["pkg_height"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Dimension Unit Package"), response.found[indexFound]["pkg_dimensions_unit"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Package Weight"), response.found[indexFound]["pkg_weight"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Weight Unit Package"), response.found[indexFound]["pkg_weight_unit"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("System Color"), response.found[indexFound]["color_system"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Item Number"), response.found[indexFound]["itemNumber"]);

                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("ecdid"), response.found[indexFound]["companies"][indexCompany]["ecdid"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Company"), company);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("MSRP"), response.found[indexFound]["companies"][indexCompany]["msrp"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("PVP"), response.found[indexFound]["companies"][indexCompany]["pvp"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("UPC"), response.found[indexFound]["companies"][indexCompany]["upc"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Vendor"), vendor);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Replenishment"), response.found[indexFound]["companies"][indexCompany]["replenishment_type"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Purchase Status"), response.found[indexFound]["companies"][indexCompany]["purchase_status"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Cost"), response.found[indexFound]["companies"][indexCompany]["cost"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Landed Cost"), response.found[indexFound]["companies"][indexCompany]["landed_cost"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Currency"), response.found[indexFound]["companies"][indexCompany]["currency"]);

                    // instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Fob Vendor"), response.found[indexFound]["fob_vendor"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Customer Type"), response.found[indexFound]["customer_type"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Tax"), response.found[indexFound]["tax"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Serie"), Boolean(response.found[indexFound]["is_serial"]));
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Tipo de Cambio Alta"), response.found[indexFound]["tc_alta_mx"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Margen Máximo VMX %"), response.found[indexFound]["max_margin_vmx"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Vendor Retail VMX Máximo %"), response.found[indexFound]["max_vendor_margin"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("% OFF MSRP"), response.found[indexFound]["msrp_off"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Master Pack Quantity"), response.found[indexFound]["master_pack_qty"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Inner Pack Quantity"), response.found[indexFound]["inner_pack_qty"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("MOQ"), response.found[indexFound]["moq"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Tipo Empaque SAT"), satPacking);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Material Peligroso"), Boolean(response.found[indexFound]["hazard_material"]));
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Codigo Material Peligroso"), hazard_code);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Unidad de medida"), response.found[indexFound]["unit_of_measurement"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Site"), response.found[indexFound]["site"]);

                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Units per Pallet"), response.found[indexFound]["units_per_pallet"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("EAN"), response.found[indexFound]["ean"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Sistema métrico"), response.found[indexFound]["metric_system"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Schedule B"), response.found[indexFound]["schedule_b"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("País de Origen"), response.found[indexFound]["country_of_origin"]);
                    instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Material de composición"), response.found[indexFound]["material_composition"]);
                    // instance.setDataAtCell(instance.countRows()-1, instance.propToCol("Canal"), response.found[indexFound]["channel "]);

                }
            }
            if(response.notfound.length > 0){
                Swal.fire({icon: "warning", title: "No se encontraron", text: response.notfound.join(","), showConfirmButton: true});
            }
            // instance.validateCells();
        });
    }
    const showAddProducts = () =>{
        if (props.edit){
            return(
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Agregar productos:</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control placeholder="Ejemplo: 999999999999, 999999999998" aria-label="UPCS" onKeyUp={(e)=>{setSearchField(e.target.value)}} />
                            <Button variant="secondary" onClick={(e)=>{searchProducts(e)}}> Agregar </Button>
                        </InputGroup>
                    </Form.Group>
                </>
            );
        }
    }
    const showButtons = () =>{
        if (!props.edit){
            return(
                <>
                    <Button variant="dark" onClick={() => {createNewProduct()}}> Crear renglón </Button>{" "}                                
                    <Button variant="dark" disabled={getisDisable} onClick={() => {createNewVariant(0)}}> Crear variante </Button>{" "}
                    <Button variant="dark" disabled={getisDisable} onClick={() => {createNewVariant(1)}}> Crear variante compañia </Button>{" "}                    
                </>
            );
        }
    }
    const showBasicControl = () =>{
        if (!props.readOnly){
            return(
                <>
                    <Button variant="dark" onClick={() => {removeProduct()}}><TrashFill/> Eliminar renglón </Button>{" "}
                    {(props.edit) ? <></> : <><Button variant="dark" onClick={() => {clearTable()}}><TrashFill/> Borrar tabla </Button>{" "}</>}
                    <Button variant="danger" onClick={() => {create_edit()}}><SendFill/> {props.edit ? "Guardar" : "Crear"} </Button>
                </>
            );
        }
        //else{
        //    return(
        //        <>
        //            <DropdownButton as={ButtonGroup} variant={"dark"} title={<><FileEarmarkArrowUpFill/>{" "}Exportar</>} menuVariant="dark" >
        //                <Dropdown.Item eventKey="1" onClick={()=>exportarCSV()}>.csv</Dropdown.Item>
        //                <Dropdown.Item eventKey="2" onClick={()=>exportarXLSX()}>.xlsx</Dropdown.Item>
        //            </DropdownButton>
        //        </>
        //    );
        //}
    }
    const create_edit = async()=>{
        const instance = ProductsHandsonTable.current.hotInstance;
        instance.validateCells();
        const required = [
            "Brand",
            "Item Number",
            "UPC",
            "Item Description",
            "System Color",
            "Department",
            "Class",
            "Subclass",
            "Replenishment",
            "Purchase Status",
            "Company",
            "Customer Type",
            "Tax",
            "Vendor",
            "Serie",
            "Cost",
            "Currency",
            "Landed Cost",
            "Currency",
            "MSRP",
            "PVP",
            "Tipo de Cambio Alta",
            "Margen Máximo VMX %",
            "Vendor Retail VMX Máximo %",
            "FOB",
            // "Fob Vendor",
            "% OFF MSRP",
            "MOQ"
        ];
        const invalid = ["", "null", "undefined", null, undefined];        
        let listError = [];
        let isValid = true;
        let list = [];
        for (let rowIndex = 0; rowIndex < instance.countRows(); rowIndex++) {
            
            if(invalidRows.includes(rowIndex)){ continue; }

            let serieData = (instance.getDataAtRowProp(rowIndex, "Serie") === "1");
            instance.setDataAtRowProp(rowIndex,"Serie", serieData);

            let row_item = {
                row: rowIndex,
                upc: instance.getDataAtRowProp(rowIndex, "UPC"),
                company: instance.getDataAtRowProp(rowIndex, "Company")
            }

            if(invalid.includes(row_item.upc) || invalid.includes(row_item.company) ){
                continue;
            }
            let errorRequired = [];
            for (let index = 0; index < required.length; index++) {
                if(invalid.includes(instance.getDataAtRowProp(rowIndex, required[index]))){
                    errorRequired.push(required[index])
                    isValid = false;
                }
            }
            if(!isValid){
                row_item.errors = errorRequired;
                listError.push(row_item);
                continue; 
            }

            let result = list.find(({ upc, company }) => upc === row_item.upc && company === row_item.company);
            if(!result){
                list.push(row_item);
            }
            else{
                const index = list.findIndex(({row, upc, company}) => upc === row_item.upc && company === row_item.company);
                list.splice(index, 1);
                list.push(row_item);
            }
        }

        list.sort((a, b) => {
            const upcA = a.upc.toUpperCase();
            const upcB = b.upc.toUpperCase();
            if (upcA < upcB) {
                return -1;
            }
            if (upcA > upcB) {
                return 1;
            }
            return 0;
        });

        let companies = [];
        let products = [];

        for (let index = 0; index < list.length; index++) {

            let product = list[index];
            let company = getDataFromItem("companies", "name", instance.getDataAtRowProp(product.row, "Company"));
            let vendor = getDataFromItem("vendors", "lid", instance.getDataAtRowProp(product.row, "Vendor"));            

            let companyItem = {
                ecdid: instance.getDataAtRowProp(product.row, "ecdid"),
                company_name : company.name,
                company_code : company.code,
                company_id : company.ecid,
                vendor : vendor.lid,
                vendor_id : vendor.evid,
                status_purchases : instance.getDataAtRowProp(product.row, "Purchase Status"),
                replenishment : instance.getDataAtRowProp(product.row, "Replenishment"),
                currency : instance.getDataAtRowProp(product.row, "Currency"),
                msrp : instance.getDataAtRowProp(product.row, "MSRP"),
                cost : instance.getDataAtRowProp(product.row, "Cost"),
                landed_cost : instance.getDataAtRowProp(product.row, "Landed Cost"),
                pvp : instance.getDataAtRowProp(product.row, "PVP"),
            }
            companies.push(companyItem);
            if(index !== list.length - 1){
                if(list[index + 1].upc === product.upc){ continue; }
            }

            let brand = getDataFromItem("brands", "name", instance.getDataAtRowProp(product.row, "Brand"));
            let department = getDataFromItem("departments", "name", instance.getDataAtRowProp(product.row, "Department"));
            let mainclass = getDataFromItem("mainclasses", "name", instance.getDataAtRowProp(product.row, "Class"));
            let subclass = getDataFromItem("subclasses", "name", instance.getDataAtRowProp(product.row, "Subclass"));
            let color = getDataFromItem("colors", "name", instance.getDataAtRowProp(product.row, "System Color"));
            let country_of_origin = getDataFromItem("countries", "name", instance.getDataAtRowProp(product.row, "País de Origen"));
            let sat_hazard_code = getDataFromItem("sat_hazard_codes", "description", instance.getDataAtRowProp(product.row, "Codigo Material Peligroso"));
            let sat_packing_spID = getDataFromItem("sat_pkg_types", "description", instance.getDataAtRowProp(product.row, "Tipo Empaque SAT"));

            let productItem = {
                itemNumber: instance.getDataAtRowProp(product.row, "Item Number"),
                upc: instance.getDataAtRowProp(product.row, "UPC"),
                department: department.name,
                department_id: department.edid,
                mainclass: mainclass.name,
                mainclass_id: mainclass.emcid,
                subclass: subclass.name,
                subclass_id: subclass.escid,
                bum: null,
                fob : instance.getDataAtRowProp(product.row, "FOB"),
                brand: brand.name,
                brand_id: brand.ebid,
                item_description: instance.getDataAtRowProp(product.row, "Item Description"),
                color_system: color.name,
                color_id: color.ecoid,

                customer_type: instance.getDataAtRowProp(product.row, "Customer Type"),
                tax: instance.getDataAtRowProp(product.row, "Tax"),
                is_serial: instance.getDataAtRowProp(product.row, "Serie"),
                tc_alta_mx: instance.getDataAtRowProp(product.row, "Tipo de Cambio Alta"),
                max_margin_vmx: instance.getDataAtRowProp(product.row, "Margen Máximo VMX %"),
                max_vendor_margin: instance.getDataAtRowProp(product.row, "Vendor Retail VMX Máximo %"),
                fob_vendor: instance.getDataAtRowProp(product.row, "Fob Vendor"),
                msrp_off: parseFloat(instance.getDataAtRowProp(product.row, "% OFF MSRP").replace("%","")),
                master_pack_qty: instance.getDataAtRowProp(product.row, "Master Pack Quantity"),
                inner_pack_qty: instance.getDataAtRowProp(product.row, "Inner Pack Quantity"),
                moq: instance.getDataAtRowProp(product.row, "MOQ"),
                sat_packing_type: sat_packing_spID.spid,
                hazard_material: instance.getDataAtRowProp(product.row, "Material Peligroso"),
                hazard_material_code: sat_hazard_code.shcid,
                unit_of_measurement: instance.getDataAtRowProp(product.row, "Unidad de medida"),
                site: instance.getDataAtRowProp(product.row, "Site"),

                units_per_pallet: instance.getDataAtRowProp(product.row, "Units per Pallet"),
                ean: instance.getDataAtRowProp(product.row, "EAN"),
                metric_system: instance.getDataAtRowProp(product.row, "Sistema métrico"),
                schedule_b: instance.getDataAtRowProp(product.row, "Schedule B"),
                country_of_origin: country_of_origin.name,
                country_id: country_of_origin.ecnid,
                country_code: country_of_origin.code,
                material_composition: instance.getDataAtRowProp(product.row, "Material de composición"),

                // channel : instance.getDataAtRowProp(product.row, "Canal"),

                measurements: {
                    product:{
                        length: instance.getDataAtRowProp(product.row, "Product Length"),
                        width: instance.getDataAtRowProp(product.row, "Product Width"),
                        height: instance.getDataAtRowProp(product.row, "Product Height"),
                        dimensions_unit: instance.getDataAtRowProp(product.row, "Dimension Unit Product"),
                        weight: instance.getDataAtRowProp(product.row, "Product Weight"),
                        weight_unit: instance.getDataAtRowProp(product.row, "Weight Unit Product"),
                    },
                    package:{
                        length: instance.getDataAtRowProp(product.row, "Package Length"),
                        width: instance.getDataAtRowProp(product.row, "Package Width"),
                        height: instance.getDataAtRowProp(product.row, "Package Height"),
                        dimensions_unit: instance.getDataAtRowProp(product.row, "Dimension Unit Package"),
                        weight: instance.getDataAtRowProp(product.row, "Package Weight"),
                        weight_unit: instance.getDataAtRowProp(product.row, "Weight Unit Package"),
                    }
                },
                companies: [...companies],
            };
            products.push(productItem);
            companies = [];
        }
        const errorDisplay = listError.map((item)=>{
            return "UPC: " + item.upc + " ERRORS: [" + item.errors.join(", ") + "]"; 
        })
        if(products.length < 1){
            Swal.fire({
                icon: "warning",
                title: "Espera!",
                html: "No hay productos validos <br/>" + errorDisplay.join("<br/>"),
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: "Cancelar"
            })
        }
        else{            
            Swal.fire({
                icon: "warning",
                title: "Espera!",
                text: "Se enviaran " + list.length + " productos " + ((invalidRows.length > 0) ? " y " + invalidRows.length + " no se enviaran porque tienen errores" : ""),
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: "Cancelar"
            }).then(async(result) => {
                if(result.isConfirmed){
                    if(props.edit){
                    await putFetchToken("product/createComplete", JSON.stringify(products)).then(response => {
                        if(response.success){
                            Swal.fire({icon: "success", title: "Guardado", text: response.msg, showConfirmButton: true});
                            for (let index = 0; index < list.length; index++) {
                                instance.alter('remove_row', list[index].row, 1);
                            }
                        }
                        else{
                            Swal.fire({icon: "error", title: "Error!", text: response.error, showConfirmButton: true});
                        }
                    });
                }
                else{
                   await postAxios("product/createComplete", JSON.stringify(products)).then(response => {
                        if(response.success){
                            if(response.errors.length > 0){
                                let list_upc_errors = [];
                                for (let index = 0; index < response.errors.length; index++) {
                                    list_upc_errors.push(response.errors[index].upc)
                                }
                                Swal.fire({icon: "error", title: "Error!", text: "Error en servidor por estos upcs: " + list_upc_errors.join(","), showConfirmButton: true});
                            }
                            else{
                                Swal.fire({icon: "success", title: "Creados", text: response.msg, showConfirmButton: true});
                                for (let index = 0; index < list.length; index++) {
                                    instance.alter('remove_row', list[index].row, 1);
                                }
                            }
                            
                        }
                        else{
                            Swal.fire({icon: "error", title: "Error!", text: response.error, showConfirmButton: true});
                        }
                    });
                }
                }
            });
        }
    }
    const s2ab = (s) =>{
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }
    const exportarCSV = () =>{
        const instance = ProductsHandsonTable.current.hotInstance;
        let data = instance.getData();
        let headers = [];
        for (let index = 3; index < instance.countCols(); index++) {
            headers.push(instance.getColHeader(index))
        }
        let csv = [headers.join(";")  + "\n"];
        for (let index = 0; index < data.length; index++){
            let row = [];
            for (let rowIndex = 3; rowIndex < data[index].length; rowIndex++) {
                row.push(data[index][rowIndex]);
            }
            csv.push((row.join(";") + "\n"));
        }
        let date = new Date().toLocaleDateString("es-Mx").replaceAll("/", "-");
        saveAs(new Blob(csv,{type:"text/plain;charset=utf-8"}), "Products " + date + ".csv");
    }
    const exportarXLSX = () =>{
        const instance = ProductsHandsonTable.current.hotInstance;
        let data = instance.getData();
        let headers = [];
        for (let index = 3; index < instance.countCols(); index++) {
            headers.push(instance.getColHeader(index))
        }
        let newData = [];
        for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
            let row = [];
            for (let colIndex = 3; colIndex < data[rowIndex].length; colIndex++) {
                row.push(data[rowIndex][colIndex]);
            }
            newData.push(row);
        }
        let save = [headers, ...newData];
        //let save = [headers, ...instance.getData()];
        const ws = XLSX.utils.aoa_to_sheet(save);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Hoja 1");
        var wbout = XLSX.write(wb, {bookType:"xlsx", bookSST:true, type:"binary"});
        let date = new Date().toLocaleDateString("es-Mx").replaceAll("/", "-");
        saveAs(new Blob( [s2ab(wbout)], {type:"application/octet-stream"}), "Products " + date + ".xlsx");
    }
    function getBrowserType() {
        const test = regexp => {
            return regexp.test(navigator.userAgent);
        };

        if (test(/opr\//i) || !!window.opr) {
            return 'Opera';
        } else if (test(/edg/i)) {
            return 'Microsoft Edge';
        } else if (test(/chrome|chromium|crios/i)) {
            return 'Google Chrome';
        } else if (test(/firefox|fxios/i)) {
            return 'Mozilla Firefox';
        } else if (test(/safari/i)) {
            return 'Apple Safari';
        } else if (test(/trident/i)) {
            return 'Microsoft Internet Explorer';
        } else if (test(/ucbrowser/i)) {
            return 'UC Browser';
        } else if (test(/samsungbrowser/i)) {
            return 'Samsung Browser';
        } else {
            return 'Unknown browser';
        }
    }
    // window.onblur = function () {
    //     //  https://595c-2806-2f0-5281-ffb3-d000-dcf7-caad-65f9.ngrok-free.app
    //     const instance = ProductsHandsonTable.current.hotInstance;
    //     let selection = instance.getSelectedRangeLast();
    //     if(!selection){
    //         return;
    //     }
    //     let cell = instance.getCell(selection.to.row, selection.to.col);        
    //     if(navigator.platform.toUpperCase().indexOf('MAC') >= 0 && selection !== undefined){
    //         //bufferTable.current.hotInstance.selectCell(0, 0);
    //         let cell = instance.getCell(selection.to.row, selection.to.col)
    //         const browser = getBrowserType();
    //         let keyCode = null;
    //         switch (browser) {
    //             case "Mozilla Firefox":
    //                 keyCode = 224
    //                 break;
    //             case "Opera":
    //                 keyCode = 17
    //                 break;
    //             case "Apple Safari":
    //             case "Google Chrome":
    //                 keyCode = 91
    //                 break;
    //             default:
    //                 break;
    //         }
    //         cell.addEventListener("keyup", (event) => {
    //             console.log(event);
    //         });         
    //         cell.dispatchEvent(
    //             new KeyboardEvent("keyup", {
    //                 key: "meta",
    //                 charCode: 0,
    //                 keyCode: keyCode, // example values.
    //                 code: "", // put everything you need in this object.
    //                 which: 69,
    //                 shiftKey: false, // you don't need to include values
    //                 ctrlKey: false,  // if you aren't going to use them.
    //                 metaKey: false   // these are here for example's sake.
    //             })
    //         );
    //     }
    // }
    return(
        <>
        <SideMenu title={props.title}/>
        <Container className="marginTopContainer">
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active href="/taskManager">Administrador de tareas</Breadcrumb.Item>
                        <Breadcrumb.Item active href="#">{props.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12} lg={(props.edit) ? 8 : 0}>
                    {showAddProducts()}
                </Col>
                <Col sm={12} md={12} lg={(props.edit) ? 4 : 12}>
                    <Form.Label>{"\u00A0"}</Form.Label>
                    <ButtonToolbar className="justify-content-end">
                        <ButtonGroup>
                            <div>
                                {showButtons()}
                                {showBasicControl()}
                            </div>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <HotTable id="mainTable" ref={ProductsHandsonTable} settings={ProductsHandsonTableSettings}/>
                    {/* virtual buffer table  */}
                    {/*<HotTable id="bufferTable" ref={bufferTable} settings={buferTableSettings}/>*/}
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default CreateProduct;