import React, { useState, useEffect, useRef } from "react";
import SideMenu from "../Components/sideMenu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import Select from "react-select";
import { getAxios, putFetchToken} from "../Components/commonFunctions";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2"

const Discounts = (props) =>{
const selectInputRef = useRef();

const [getOptions, setOptions] = useState([]);
const [getBrandSelected, setBrandSelected] = useState();
const [getDiscount, setDiscount] = useState("");

useEffect(()=>{
    getBrands();
    // eslint-disable-next-line
},[]);

const getBrands = async() => {
    await getAxios("brands?discount=1").then(response => {
        let options = [];
        for (let index = 0; index < response.length; index++) {
            options.push({value:response[index].bid, label:response[index].name, bid:response[index].bid, discount:response[index].discount})
        }
        setOptions(options);
    });
}

const maxNumber = (value) =>{
    var charCode = value.charCodeAt(value.length-1)
    if((charCode >= 48 && charCode <=57 && value.length <= 2) || value === ""){
        setDiscount(value);
    }  
}

const ShowInputDiscount = () => {
    if(getBrandSelected != null){
        return(
            <Row id="inputs">
                <Col>
                    <div className="card">
                        <h5 className="card-header">{getBrandSelected.label}</h5>
                        <div className="card-body">
                            <h5 className="card-title">Descuento</h5>
                            <InputGroup>
                                <Form.Control onChange={(e) => {maxNumber(e.target.value)}} value={getDiscount} placeholder="%" aria-label="%" aria-describedby="basic-addon2"/>
                            </InputGroup>
                            <br/>
                            <Button  onClick={(e) => {send()}} className="btn btn-primary">Actualizar</Button>{"\u00A0"}{"\u00A0"}
                            <Button  onClick={(e) => {cancel()}} className="btn btn-primary">Cancelar</Button>                            
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
    else{
        return(<></>)
    }
}

const cancel = () => {
    setBrandSelected(null);
    setDiscount("");
    selectInputRef.current.clearValue();
}

const send = async() => {
    let brand = getBrandSelected;
    brand.discount = getDiscount;
    await putFetchToken("brands", JSON.stringify([brand])).then(response => {
        if(response.success){
            Swal.fire({icon: "success", title: "Actualizado", text: "Descuento actualizado", showConfirmButton: false, timer: 1500});
        }
    });
}

const changeValue = (e) =>{
    setBrandSelected(e)
    setDiscount(e.discount)
}

return(
        <>
        <SideMenu title={props.title}/>
        <Container className="marginTopContainer">
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active href="/taskManager">Administrador de tareas</Breadcrumb.Item>
                        <Breadcrumb.Item active href="#">{props.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 className="card-header">Buscar Marca:</h5><br/>
                    <Select ref={selectInputRef} placeholder="Marcas" className="overTable selectOptions" onChange={(e) => {changeValue(e)}} options={getOptions}  isClearable></Select>
                </Col>
            </Row>
            <br/>
            {ShowInputDiscount()}
            
        </Container>
        </>
    );
}

export default Discounts;